.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.success {

}

.error {

}

.header {
    padding: 0;
    margin: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    font-family: inherit;
    text-align: center;
    user-select: none;
}