.container {
  position: relative;
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  height: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.above {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.header {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  font-family: inherit;
  user-select: none;
}

.button {
  position: absolute;
  right: 8px;
}