.field {
  position: relative;
  width: 100%;
  height: 46px;
  border-radius: 4px;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
  background-color: rgba(19, 26, 25, 0.85);
}

/*.field::placeholder {*/
/*  color: #ffffff;*/
/*}*/

.field:hover {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  background-color: rgba(19, 26, 25, 0.70);
}

.field.active {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.field.active .input {
  padding: 24px 16px 8px 62px;
  border-color: unset;
  box-shadow: unset;
}

.field.active label {
  top: 4px;
  opacity: 1;
  color: rgba(19, 26, 25, 0.85);
}

.field.locked {
  pointer-events: none;
}

.field .input {
  width: 100%;
  height: 46px;
  position: relative;
  padding: 0 16px 0 62px;
  border: none;
  border-radius: 4px;
  /*font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #282828;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
  0.1s padding ease-in-out;
  -webkit-appearance: none;
}

.field .input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field .input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field .input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field .input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

/*.field.active > div > div {*/
/*  border-color: red;*/
/*}*/

.label {
  position: absolute;
  top: 24px;
  left: 62px;
  /*font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: 0.1s all ease-in-out;
}

.label.error {
  color: #ec392f !important;
}

.field p.predicted {
  position: absolute;
  top: 8px;
  left: 62px;
  /*font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e0e0e0;
  opacity: 1;
  pointer-events: none;
}

.countries-container span {
  color: #000000;
}

.button {
  border-right: 2px solid rgba(255, 255, 255, 0.3);
}

.field.active .button {
  border-right: 2px solid rgba(0, 0, 0, 0.3);
}