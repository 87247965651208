.button-svg {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  text-transform: none;
  margin: 0;
  border: none;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-align: center;
  cursor: pointer;
  background: none;
  appearance: none;
  box-sizing: border-box;
  vertical-align: middle;
}