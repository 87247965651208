.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
    width: 100%;
    gap: 8px;
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;

}

.cropper {
    background: #0a0b0d;
    border-radius: 4%;
}

.area {
    width: 50%;
    border-radius: 50%; /* Tworzy okrągły obszar przycinania */
}