.field {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(19, 26, 25, 0.85);
    transition: 0.2s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}

.field:hover {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    background-color: rgba(19, 26, 25, 0.70);
}

.field.active {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.field.active input {
    padding: 24px 16px 8px 16px;
}

.field.active input + label {
    top: 4px;
    opacity: 1;
    color: rgba(19, 26, 25, 0.85);
}

.field.locked {
    pointer-events: none;
}

.field input {
    width: 100%;
    height: 48px;
    position: relative;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    /*font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*/
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 48px;
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
    -webkit-appearance: none;
}

.field input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    user-select: none;
}
.field input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
    user-select: none;
}
.field input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
    user-select: none;
}
.field input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
    user-select: none;
}

.field input + label {
    position: absolute;
    top: 24px;
    left: 16px;
    /*font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*/
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
}

.field.error input + label {
    color: #ec392f;
    top: 4px;
    opacity: 1;
}

.field.error {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    animation: vibrate 0.5s ease-in-out;
    /*border: 1px solid #ec392f;*/
}

.field.error input {
    padding: 24px 16px 8px 16px;
    border: 1px solid #ec392f;
    box-shadow: 0px 4px 20px 0px rgba(145, 20, 20, 0.2);
}


.field p.predicted {
    position: absolute;
    top: 8px;
    left: 16px;
    /*font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*/
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #e0e0e0;
    opacity: 1;
    pointer-events: none;
}

.error-info {
    margin: 4px 0;
    color: #ff0000;
}

@keyframes vibrate {
    10% { transform: translateX(10px); }
    20% { transform: translateX(-10px); }
    30% { transform: translateX(10px); }
    40% { transform: translateX(-10px); }
    50% { transform: translateX(5px); }
    60% { transform: translateX(-5px); }
    70% { transform: translateX(2px); }
    80% { transform: translateX(-2px); }
    90% { transform: translateX(1px); }
    100% { transform: translateX(-1px); }
}

.vibrating {
    animation: vibrate 0.5s ease-in-out;
}

/*.field-error {*/
/*  */
/*}*/

.eye-button {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    /*background: rgba(0, 0, 0, 0.7);*/
    border: 0;
}
/*.field.active*/
.eye-button svg {
    fill: rgba(255, 255, 255, 0.8);
}

.field.active .eye-button svg,
.field.error .eye-button svg {
    fill: #282828;
}