/* Main switch container */
.switch {
    position: relative;
    display: flex;
    align-items: center; /* Vertically center the items */
    justify-content: start; /* Place the items on opposite ends */
    /*width: 100%; !* Adjust the width as needed *!*/
    width: 100%; /* Adjust the width as needed */
    height: 100%;
}

/* Hide the default checkbox */
.switch input {
    opacity: 0;
    position: absolute;
    width: 55px;
    height: 34px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* Create the slider */
.slider {
    position: relative;
    cursor: pointer;
    width: 60px; /* Slider width */
    height: 34px; /* Slider height */
    background-color: rgba(9, 16, 15, 0.85);
    border-radius: 34px;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #ffffff;
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
}

.active .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Label styling */
.label {
    /*width: 100%;*/
    color: rgba(9, 16, 15, 0.85);
    margin-left: 10px; /* Adjust as needed */
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.label:active {
    outline: none;
}

.label:active {
    background-color: transparent; /* Usuwa podświetlenie podczas kliknięcia */
}

/* Active state styling */
.active .slider {
    background-color: #2196F3;
}
