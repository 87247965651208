.options {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: rgba(9, 16, 15, 0.85);
    /*background-color: #282828;*/

    /*background-color: #4a90e2;*/
    /*background-color: #7376B8;*/
    /*background-color: #111827;*/

    /*background: rgba(0, 115, 255, 0.7);*/
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.options li {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    /*border-bottom: 1px solid #dbdbdb;*/
    border-bottom: 1px solid #5a5a5a;
    cursor: pointer;
    transition: background-color 0.2s;
}

.options li:last-child {
    border-bottom: none;
}

.options li:hover {
    background-color: #383838;
}
