.button {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  /*background-color: rgba(255, 255, 255, 0.3);*/

  /*font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*/
  font-size: 14px;
  font-weight: 500;
  line-height: 48px; /* Aby wycentrować tekst wertykalnie */
  /*color: rgb(140, 88, 150);*/
  user-select: none;

  border: none;
  outline: none;
  cursor: pointer; /* Kursor zmienia się na rękę, wskazując, że przycisk jest klikalny */
  text-align: center; /* Aby wycentrować tekst horyzontalnie */
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.3s color ease-in-out;

  /*background: rgba(19, 26, 25);*/
  background-color: rgba(9, 16, 15, 0.85);
  color: #ffffff;
}

.button:hover {
  /*background-color: rgb(140, 88, 150);*/
  /*box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);*/
  color: #ffffff;

  background-color: rgba(63, 70, 69);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.35);
}

.button:active {
  /*background-color: rgb(124, 78, 133);*/
  /*box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);*/
  color: #ffffff;

  background-color: rgba(19, 26, 25, 0.85);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.button:disabled {
  pointer-events: none;
  /*background-color: rgba(255, 255, 255, 0.1);*/
  color: rgba(255, 255, 255, 0.5);

  background-color: rgba(37, 46, 44, 0.75);
}

.button.purple-reverse-theme {
  background-color: rgb(140, 88, 150);
  color: #ffffff;
}

.button.purple-reverse-theme:hover {
  background-color: rgb(124, 78, 133);
}

.button.purple-reverse-theme.active {
  background-color: rgb(112, 70, 121);
}