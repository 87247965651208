.container {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 0;
    height: 56px;
    padding: 0;
    font: inherit;
    overflow: hidden;
    color: #585b85;
    vertical-align: baseline;
    border-radius: 16px;

    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*height: 56px;*/
    /*z-index: 0;*/
    /*position: relative;*/
    /*font: inherit;*/
    /*padding: 0;*/

    /*transition: transform 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s;*/
    /*box-shadow: 8px 5px 6px 0 rgba(119, 138, 189, 0.3);*/
    /*backdrop-filter: blur(0px);*/
    /*-webkit-backdrop-filter: blur(0px);*/
}

/*.container:hover button:last-child {*/
/*    display: flex;*/
/*}*/

.active {
    width: 100%;
}

.disabled {
    width: 90%;
    border-radius: 16px;
    background-size: 25px 25px;
    background-image: repeating-linear-gradient(135deg, rgba(93, 93, 93, 0.6) 0px, rgba(93, 93, 93, 0.6) 0.5px, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 50%), repeating-linear-gradient(45deg, rgba(93, 93, 93, 0.6) 0px, rgba(93, 93, 93, 0.6) 0.5px, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 50%);
}