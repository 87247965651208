.header {
    margin-bottom: 4px;
}

.container {
    word-wrap: break-word;
    color: #414141;
    font-size: 14px;
    font-weight: 400;
    padding: 0 4px;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
}