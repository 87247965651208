.toggle-button {
    margin: 16px 0;
}

.logout {
    border-radius: 12px;
    background-color: rgb(206, 67, 67) !important;
}

.logout:hover {
    background-color: rgba(255, 63, 63, 0.85) !important;
    /*background-color: rgb(244, 72, 72);*/
}

.logout:active {
    background-color: rgba(255, 39, 39, 0.85) !important;
}