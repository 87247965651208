.option {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    transition: background-color 0.2s;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    -webkit-touch-callout: none; /* Wyłącza menu kontekstowe na urządzeniach iOS */
    -webkit-user-select: none;
}

.option:hover {
    /*background-color: #383838;*/
    /*background-color: #374151;*/

    background-color: rgba(63, 70, 69, 1);
}

.option:active {
    /*background-color: #181818;*/
    /*background-color: #374151;*/

    background-color: rgba(19, 26, 25, 0.85);
}