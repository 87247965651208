/*.container {*/
/*  width: 500px;*/
/*  !*height: 100%;*!*/
/*  margin: auto;*/
/*  padding: 48px;*/
/*  !*background: rgba(187, 236, 174, 0.55);*!*/
/*  !*background: rgba(0, 0, 0, 0.52);*!*/
/*}*/

/*.container h2 {*/
/*  text-align: center;*/
/*  !*margin: auto;*!*/
/*  font-size: 1.75rem;*/
/*  color: #ffffff;*/
/*  !*font-family: inherit;*!*/
/*  font-family: Inter,Helvetica,Arial,system-ui,-apple-system;*/
/*}*/

/*.container div {*/
/*  !*margin: 16px auto;*!*/
/*}*/

.form {
  /*margin: auto;*/
  /*max-width: 500px;*/
}

.header {
  font-size: 2rem;
  text-align: center;
  /*margin-top: 0;*/
  margin: 0 auto 16px;
  color: #09100fd9;
}

.form-elements {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-elements a {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  transition: 0.2s color ease-in-out;
}

.form-elements a:hover {
  color: rgb(70, 70, 70);
}

.button-submit {
  /*color: rgba(155, 131, 178, 0.55);*/
}

.reset-password {
  margin: 0;
  text-align: center;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
}

.switch-mode {
  margin: 0;
  text-align: center;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
}

.error {
  margin: 2px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #ec392f;
}
.success {
  margin: 2px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #0116ff;
}

.choice {
  display: flex;
  flex-direction: row;
}

.separator {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  margin: auto 8px;
  height: 1px;
  background: rgb(0, 0, 0);
}

.info {
  padding: 0 4px;
  text-align: center;
  /*text-align: justify;*/
  /*text-align-last: center; !* Opcjonalnie, aby wyśrodkować ostatnią linię *!*/
  /*hyphens: auto;*/
  color: rgb(65, 65, 65);
  font-size: 14px;
  font-weight: 400;
  white-space: pre-line;
  word-wrap: break-word;
  word-break: break-word;
}

.prime-info {
  font-weight: 600;
}