.modal-overlay {
  position: fixed;
  display: flex;
  inset: 0;
  z-index: 10011;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  justify-content: center;
  padding: 0;
  /*font-family: inter;*/
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 576px) {
  .modal-overlay {
    -webkit-box-align: center;
    align-items: center;
  }
}

/*.modal-children-wrapper {*/
/*  height: auto;*/
/*  max-height: 100%;*/
/*  overflow-y: auto;*/
/*}*/