.container {

}

.active {

}

.disabled {
    background-color: rgba(255, 255, 255, 0);
}

.title {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
    user-select: none;
}