.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: auto;
    padding: 16px;
}

.form {
    width: 400px;
}

.above {
    position: absolute;
    /*right: 50%;*/
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Wysokość połowy ekranu minus połowa wysokości formularza */
    height: calc(25vh); /* Wysokość połowy ekranu minus połowa wysokości formularza */
    /*width: 100%;*/
    /*margin-bottom: 0; !* Odstęp od formularza poniżej *!*/
}

.above img {
    max-width: 200px; /* Maksymalna szerokość obrazu */
    height: auto; /* Wysokość dostosowuje się proporcjonalnie */
    display: block; /* Zapewnia poprawne wyśrodkowanie w kontenerze flex */
    margin: 0 auto; /* Dodatkowe wyśrodkowanie w poziomie, jeśli to potrzebne */
}