.page-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 680px;
  min-height: 100vh;
  padding: 64px 16px 16px;
}

.main-container {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*margin: 0 auto;*/
  /*width: 100%;*/
  /*max-width: 680px;*/
  /*min-height: 100vh;*/
}

.container {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: 680px;
}

/*.container {*/
/*  !*position: relative;*!*/
/*  !*display: flex;*!*/
/*  !*justify-content: center;*!*/
/*  !*align-items: center;*!*/
/*  !*height: 100vh;*!*/
/*  !*width: 100%;*!*/
/*  !*margin: auto;*!*/
/*  */
/*  !*padding: 16px;*!*/
/*  !*color: #fff;*!*/
/*  !*background: rgba(82, 52, 114, 0.5);*!*/
/*  !*border-radius: 16px;*!*/
/*  !*box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);*!*/
/*  !*backdrop-filter: blur(7.3px);*!*/
/*  !*-webkit-backdrop-filter: blur(7.3px);*!*/
/*}*/

.content {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 64px 16px 32px;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .content {
    padding-bottom: 64px;
  }
}

.header {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.picture {
  margin-bottom: 16px;
}

.username {
  margin: 0;
  /*color: rgb(0, 0, 0);*/
  color: #09100fd9;
  /*color: rgba(0, 0, 0, 0.95);*/
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}