.overlay {
  align-items: flex-end;
}

@media screen and (min-width: 576px) {
  .overlay {
    -webkit-box-align: center;
    align-items: center;
  }
}

.container {
  display: flex;
  flex-flow: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100vw;
  padding: 24px 8px;
  position: relative;
  height: auto;
  /*background: #FFFFFF;*/
  background: #f7f7f7;
  border-radius: 16px 16px 0 0;
}

@media screen and (min-width: 576px) {
  .container {
    width: 384px;
    border-radius: 16px;
  }
}