.header {
    margin-bottom: 0;
}

.container {
    padding: 0 16px;
}

.share-button-wrapper {
    margin: 12px 0;
    padding: 0 16px;
}

.title {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -.32px;
    line-height: 1.2;
    margin: 0;
}

.subtitle {
    color: #676b5f;
    font-size: 14px;
    letter-spacing: -.32px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

.container-buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
    margin-top: 16px;
}

.button-link {
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 48px;
    color: #ffffff;
    background-color: rgba(9, 16, 15, 0.85);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.3s color ease-in-out;
    text-decoration: none;
}

.button-link:hover {
    background-color: rgba(63, 70, 69);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.35);
}

.button-link.active {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}