.title {
    font-weight: 500;
    -webkit-hyphens: none;
    hyphens: none;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    user-select: none;
}

@media (min-width: 576px) {
    .title {
        font-size: 16px;
    }
}