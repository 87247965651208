.overlay {
  align-items: flex-end;
}

@media screen and (min-width: 576px) {
  .overlay {
    -webkit-box-align: center;
    align-items: center;
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex-flow: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100vw;
  padding: 24px 8px;
  position: relative;
  height: auto;
  /*background: #FFFFFF;*/
  background: #f7f7f7;
  border-radius: 16px 16px 0 0;
}

@media screen and (min-width: 576px) {
  .container {
    width: 384px;
    border-radius: 16px;
  }
}

.above-wrapper {
    position: relative;
    display: flex;
    margin-bottom: 24px;
    width: 100%;
    height: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.above {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.header {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  font-family: inherit;
  user-select: none;
}

.button {
    position: absolute;
    right: 16px;
    top: 19.7px
}