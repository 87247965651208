.list {
    padding: 0;
    list-style: none;
}

.list li {
    display: flex;
    justify-content: center;
    margin: 16px 0;
}

.list li:first-child {
    margin-top: 0;
}

.list li:last-child {
    margin-bottom: 0;
}
