.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  margin: auto;
  padding: 16px;
  color: #fff;
}

.container form {
  width: 400px;
}