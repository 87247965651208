.container, .text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(96, 105, 108);
  color: rgb(255, 255, 255);
  border-radius: 100%;
  height: 96px;
  width: 96px;
}

.picture {
  height: 96px;
  width: 96px;
  border-radius: 100%;
  user-select: none;
}

.text {
  font-weight: 600;
  font-size: 32px;
  font-family: inherit;
  user-select: none;
}

.clickable {
  cursor: pointer;
}