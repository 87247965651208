.button-share {
  transition: background-color 0.1s ease-in 0s;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  border: none;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-align: center;
  cursor: pointer;
  display: block;
  background: none;
  width: 100%;
  appearance: none;
  box-sizing: border-box;
  vertical-align: middle;
  text-transform: none;
}

.button-share > div {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  color: rgb(10, 11, 13);
  width: 100%;
  height: 100%;
}

.button-share > div > div {
  display: flex;
  margin-left: 16px;
  flex: 1 1 0%;
  width: 100%;
  height: 100%;
}

.button-share p {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}