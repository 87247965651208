.header {
  margin-bottom: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /*margin: 16px;*/
}

.success {
  text-align: center;
}