.container {
  /*position: sticky;*/
  /*bottom: 0;*/
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  /*margin-bottom: 16px;*/
  margin-top: auto;
}

.link {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  height: 36px;
  width: 100%;
}

.logo {
  height: 100%;
  display: block;
  width: auto;
}