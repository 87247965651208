.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.icon-button > svg {
  width: 24px;
  height: 24px;
}