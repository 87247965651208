.container {
    transition: transform 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s;
    box-shadow: 8px 5px 6px 0 rgba(119, 138, 189, 0.3);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    cursor: pointer;
}
.container:hover {
    transform: scale(1.02);
}

.active {
    background: #ffffff80;
}

.disabled {
    background-color: rgba(225, 225, 225, 0.5);
}

.contact {
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: auto;
    white-space: normal;
    background: none;
    color: inherit;
    transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s, background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99) 0s;
    overflow-wrap: break-word;
    padding: 16px 66px;
    margin: 0;
    border: none;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    width: 100%;
    appearance: none;
    box-sizing: border-box;
    vertical-align: middle;
    user-select: none;
}