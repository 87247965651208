.field {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    position: relative;
    background-color: rgba(19, 26, 25, 0.85);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}

.field:hover {
    /*background-color: rgba(255, 255, 255, 0.45);*/
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);

    background-color: rgba(19, 26, 25, 0.70);
}

.field.active {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    /*border-color: rgb(140, 88, 150);*/
    /*box-shadow: 0 0 0 1px rgb(140, 88, 150);*/
}

.field.active select {
    padding: 24px 16px 8px 16px;
}

.field.active select + label {
    top: 4px;
    opacity: 1;
    /*color: #512da8;*/
    color: rgba(19, 26, 25, 0.85);
}

.field.locked {
    pointer-events: none;
}

.field select {
    width: 100%;
    height: 100%;
    /*height: 46px;*/
    position: relative;
    /*top: -5%;*/
    /*transform: translateY(0);*/
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    /*font-family: sans-serif;*/
    font-size: 14px;
    font-weight: 400;
    /*line-height: 24px;*/
    background-color: transparent;
    color: #282828;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
    -webkit-appearance: none;
}

.field select + label {
    position: absolute;
    top: 24px;
    left: 16px;
    /*font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*/
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
}

.field.error select + label {
    color: #ec392f;
    top: 4px;
    opacity: 1;
}

.field.error {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    animation: vibrate 0.5s ease-in-out;
    /*border: 1px solid #ec392f;*/
}

.field.error select {
    padding: 24px 16px 8px 16px;
    border: 1px solid #ec392f;
    box-shadow: 0px 4px 20px 0px rgba(145, 20, 20, 0.2);
}

.field p.predicted {
    position: absolute;
    top: 8px;
    left: 16px;
    /*font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*/
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: #e0e0e0;
    opacity: 1;
    pointer-events: none;
}

.error-info {
    margin: 4px 0;
    color: #ff0000;
}

.field select:required:invalid {
    padding: 0px 16px;
    /*font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*/
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    opacity: 1;
}
/*.field option[value=""][disabled] {*/
/*    display: none;*/
/*}*/
.field option {
    color: black;
}

.arrow {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    pointer-events: none;
}

.arrow svg {
    fill: rgba(255, 255, 255, 0.8);
    transition: transform 0.3s ease-in-out;
}

.field.active .arrow svg,
.field.error .arrow svg {
    fill: #282828;
}

.field:focus-within .arrow svg {
    transform: rotate(180deg);
}


/*.eye-button svg {*/
/*    fill: rgba(255, 255, 255, 0.8);*/
/*}*/

/*.field.active .eye-button svg,*/
/*.field.error .eye-button svg {*/
/*    fill: #282828;*/
/*}*/

/*.field select {*/
/*    padding: 0px 16px;*/
/*    !*font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*!*/
/*    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;*/
/*    font-size: 14px;*/
/*    font-weight: 400;*/
/*    line-height: 24px;*/
/*    color: rgba(255, 255, 255, 0.8);*/
/*    opacity: 1;*/
/*}*/



/*.dropdown-field {*/
/*    width: 100%;*/
/*    height: 56px;*/
/*    border-radius: 4px;*/
/*    position: relative;*/
/*    background-color: rgba(255, 255, 255, 0.3);*/
/*    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;*/
/*}*/

/*.dropdown-field:hover {*/
/*    background-color: rgba(255, 255, 255, 0.45);*/
/*    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);*/
/*}*/

/*.dropdown-field select {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    padding: 0px 16px;*/
/*    border: none;*/
/*    border-radius: 4px;*/
/*    background-color: transparent;*/
/*    color: #282828;*/
/*    outline: none;*/
/*    -webkit-appearance: none;*/
/*    appearance: none;*/
/*    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;*/
/*    font-size: 16px;*/
/*    line-height: normal;*/
/*}*/

/*!* Styl dla ikony strzałki w dół, możesz dodać własną ikonę lub użyć unicode *!*/
/*.dropdown-field::after {*/
/*    content: '▼';*/
/*    position: absolute;*/
/*    right: 16px;*/
/*    top: 50%;*/
/*    transform: translateY(-50%);*/
/*    pointer-events: none;*/
/*    color: #282828;*/
/*}*/
