.icon {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 8px;
    width: 48px;
    height: 48px;
}
