.button {
    position: relative;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 56px;
    white-space: normal;
    background: none;
    transition: transform 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s;
    overflow-wrap: break-word;
    padding: 0;
    margin: 0;
    border: none;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    appearance: none;
    box-sizing: border-box;
    vertical-align: baseline;
    /*background: rgba(0, 115, 255, 0.5);*/
    /*background: rgba(0, 115, 255, 0.7);*/

    /*background: rgba(17, 24, 39, 0.6);*/
    background: rgba(40, 40, 40, 0.6);

    /*border: 2px solid #34c759;*/
    color: #ffffff;
    /*color: #34c759;*/
    border-radius: 16px;
    box-shadow: 8px 5px 6px 0 rgba(119, 138, 189, 0.3);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    outline: none;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

.button:hover {
    transform: scale(1.02);
    /*background: rgba(17, 24, 39, 0.6);*/
    /*background-color: rgba(0, 115, 255, 0.85);*/
}
.button:hover {
    display: flex;
    /*background-color: rgba(0, 115, 255, 1);*/
}